.app{
  background-color: #eee;
  color: #272730;
  font-family: Arial, Helvetica, sans-serif;
}
.main-container{
  width: 765px;
  padding: 0 10px;
  margin: 0 auto;
}
h1{
  font-family: Helvetica,Arial, sans-serif;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}
.logo{
  margin: 0 auto;
  width: 153px;
  padding: 20px;
}
.logo img{
  width: 100%;
}
.source{
  text-transform: capitalize;
}
.video{
  margin: 0 auto;
  width: 70%;
  text-align: center;
  padding: 10px;
}
.list {
    text-align: left;
    max-width: 750px;
    margin: auto;
  }
  .itemfooter{
    color:#a8090e;
    text-align: right;
    font-size: 14px;
  }
  .paginationList{
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .edit-form {
    margin: auto;
  }
  footer{
    background-color:#b4080b;
    color: #fff;;
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
  .list .MuiPagination-ul{
width: 500px;
margin: auto;
  }
  .list .MuiPagination-ul,.list .MuiPaginationItem-root,.list .MuiPaginationItem-icon{
    font-size:16px;
  }
  .list .MuiSvgIcon-root{
    width: 2em;
    height: 2em;
  }
  .app .MuiPaginationItem-page.Mui-selected, .app .MuiPaginationItem-page:hover  {
    background-color: transparent;
    color:#b4080b;
    font-weight: 700;
    
}
.app .list-group-item {
  background-color: transparent;
  border: 1px solid #272730;
  margin: 3px;
  border-radius: 5px;
}
.app .searchbtn {
  color: #fff;
  border: 1px solid #000;
  background: linear-gradient(
180deg
, rgba(254,0,0,1) 0%, rgba(168,9,14,1) 100%);
  font-size: 24px;
  height: 45px;
  width: 185px;
}
.filter{
  border: 1px solid #272730;
  border-radius: 5px;
  margin: 3px;
  text-align: center;
}
.filter .title{
  text-align: center;
  display: block;
  font-weight: Bold;
  font-size: 24px;
  margin-bottom: 14px;
}
.sourcefilter{
  list-style: none;
  display: inline-flex;
  padding: 0;
}
.sourcefilter li{
  margin: 3px 10px;
    text-align: center;
    width: 80px;
    font-size: 16px;
    text-transform: uppercase;
}
.source-block{
  width: 450px;
  display: inline-block;
  margin: 0;
  height: 145px;
}
.date-block{
  width: 285px;
  display: inline-block;
  margin: 0;
    margin-left: 10px;
    height: 145px;
}
.date-block input{
  width: 165px;
}
.date-block .react-datepicker__input-container{
  width: 165px;
}
.filterBlock{
  margin-bottom: 15px;
  width: 745px;
    display: flex;
}
.dateBlock{
  padding: 5px;
}
.dateBlock span{
  width: 25px;
  display: inline-flex;
  font-size: 20px;
}
.dateBlock .react-datepicker-wrapper{
  display: inline-flex;
}
.app .react-datepicker-popper{
  z-index: 1000;
}

.searchInput{
  height: 45px;
  font-size: 24px;
  background: transparent;
  border: 1px solid #000;
  width: 420px;
  display: inline-block;
}
.searchBlock{
  width: 80%;
  margin: 0 auto;
}
.rightText{
  text-align: right;
}
.list-group-item  .title{
  font-size:16px;
  font-weight: bold;
  color: #000;
  text-decoration: none;
}
.list-group-item .content{
  font-size: 14px;
}
.backLink{
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.video{
  text-align: center;
}
.video a, .print-btn{
  background: linear-gradient(180deg, rgba(254,0,0,1) 0%, rgba(168,9,14,1) 100%);
  font-size: 24px;
  color: #fff;
  border: none;
}
.video a{
  color: #fff !important;
    text-decoration: none !important;
    padding: 10px;
    margin: 0 auto !important;
    display: block;
    width: 159px;
}
.dateBlock .datepicker{
  width: 173px;
  position: relative;
  display: inline-flex;
}
.datepicker::after{
  content:url('/agenda.png');
  position: absolute;
  right: 12px;
    top: 3px;
}
@media print
{    
    .video a{
      display: none !important;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }
}

/* Create a custom checkbox */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: trasnparent;
  border: 1px solid #000;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
